import { Button } from '@aws-amplify/ui-react';
import { Component, ErrorInfo, ReactNode } from 'react';

// Source: https://reactjs.org/docs/error-boundaries.html
// NOTE: Only class components can be error boundaries

interface Props {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}

interface State {
  error?: Error;
  errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {};

  public static getDerivedStateFromError(error: Error): State {
    return {
      error: error,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Error Boundary:', error, errorInfo);
    this.setState((st) => {
      return {
        ...st,
        error,
        errorInfo,
      };
    });
  }

  public render() {
    if (this.state.error) {
      if (this.props.fallbackComponent) {
        return this.props.fallbackComponent;
      } else {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              textAlign: 'center',
            }}
          >
            <div
              style={{
                position: 'absolute',
                margin: 'auto',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                maxWidth: '90%',
                height: '400px',
              }}
            >
              <h4 style={{ paddingBottom: '10px' }}>
                Uh oh, something went wrong...
              </h4>
              <div
                style={{
                  display: 'flex',
                  marginBottom: '2em',
                  marginTop: '1em',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>
                <div style={{ width: '25px' }}></div>
                <Button
                  color="primary"
                  size="small"
                  onClick={() => {
                    window.location.href = '/logout';
                  }}
                >
                  Logout
                </Button>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
