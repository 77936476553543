import { FC } from "react";

import useAuth from "../hooks/auth.hook";
import { useAddStarter, useStarters } from "../hooks/starters.hook";

const StartersPage: FC = () => {
  const { logout } = useAuth();
  const startersQueryResult = useStarters();
  const { addStarter, isAdding } = useAddStarter();

  const handleAddStarter = async () => {
    await addStarter({
      attribute1: 'att1',
      attribute2: 'att2',
    });
  }

  const starters = startersQueryResult.data;

  return (
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: '2em'}}>
      <h2>Starters</h2>
      {startersQueryResult.error &&
        <div>Error: {startersQueryResult.error?.message ?? 'No Message'}</div>
      }
      {starters &&
        <div>
          {starters.map((starter) => 
            <div key={starter.starterId}>{starter.starterId}</div>
          )}
        </div>
      }
      {(startersQueryResult.isLoading || isAdding) &&
        <div>Loading...</div>
      }
       <div style={{marginTop: '1em'}}>
        <button onClick={handleAddStarter}>Add Starter</button>
      </div>
      <div style={{marginTop: '1em'}}>
        <button onClick={logout}>Logout</button>
      </div>
    </div>
  );
}

export default StartersPage;
