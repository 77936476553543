import { Button } from "@aws-amplify/ui-react";

const NoInternetPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        marginTop: '4em'
      }}>
      <h1 style={{marginBlockEnd: 0}}>Uh Oh.</h1>
      <h4>There may be something wrong with your internet connection.</h4>
      <Button
        title="Refresh"
        onClick={() => {
          const queryParams = new URLSearchParams(window?.location?.search);
          const previousRoute = queryParams.get('previousRoute');
          if (previousRoute) {
            window.location.href = `${window.location.origin}/` + previousRoute;
          } else {
            window.location.href = window.location.origin;
          }
        }}
      >
        Refresh
      </Button>
    </div>
  )
}

export default NoInternetPage;
