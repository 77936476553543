export const fetchWithTimeout = async (input: RequestInfo, init?: RequestInit | undefined, timeoutMS = 8000) => {
  let response;

  const controller = new AbortController();
  const timeout = setTimeout(() => {
    controller.abort();
  }, timeoutMS);

  try {
    response = await fetch(input, { ...init, signal: controller.signal });
  } finally {
    clearTimeout(timeout);
  }

  return response;
};
