import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { Amplify } from 'aws-amplify';
import { Authenticator } from "@aws-amplify/ui-react";
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from "../contexts/auth.context";
import { TrpcClientProvider } from "../contexts/trpc-client.context";

import LoginPage from "../pages/login.page";
import LogoutPage from "../pages/logout.page";
import NoInternetPage from "../pages/no-internet.page";
import StartersPage from "../pages/starters.page";

import ErrorBoundary from "./error-boundary.component";
import { RequireAuth } from "./require-auth.component";

import '@aws-amplify/ui-react/styles.css';
import '@fontsource/inter/variable.css';

Amplify.configure({
  aws_project_region: process.env.REACT_APP_PROJECT_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
});

function App() {
  return (
    <ErrorBoundary>
      <Authenticator.Provider>
        <BrowserRouter>
          <AuthProvider>
            <TrpcClientProvider>
              <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/logout" element={<LogoutPage />} />
                <Route path="/" element={<RequireAuth><StartersPage /></RequireAuth>} />
                <Route path="/no-internet" element={<NoInternetPage/>}/>
              </Routes>
              <ToastContainer
                position="bottom-right"
              />
            </TrpcClientProvider>
          </AuthProvider>
        </BrowserRouter>
      </Authenticator.Provider>
    </ErrorBoundary>
  );
}

export default App;
