import { createContext, FC, ReactNode, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { persistQueryClient } from '@tanstack/react-query-persist-client';
import { httpBatchLink } from "@trpc/client";
import { RequestInitEsque } from "@trpc/client/dist/internals/types";

import useAuth from "../hooks/auth.hook";
import { fetchWithTimeout } from "../utils/fetch-timeout";
import { trpc } from '../utils/trpc'
import { usePrevious } from "../hooks/previous.hook";

const TrpcClientContext = createContext({});

interface ProviderProps {
  children: ReactNode;
}

const LocalStoragePersistor = createSyncStoragePersister({storage: window.localStorage});

export const TrpcClientProvider: FC<ProviderProps> = ({ children }) => {
  const { getIdToken, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const previousIsAuthenticated = usePrevious(isAuthenticated);
  const [queryClient] = useState(() =>
    new QueryClient({
      defaultOptions: {
        queries: {
          cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
      },
    })
  );

  const [trpcClient] = useState(() => trpc.createClient({
    links: [
      httpBatchLink({
        url: `${process.env.REACT_APP_API_GATEWAY_URL}/rpc`,
        fetch: async (input: RequestInfo | URL, init?: RequestInit | RequestInitEsque) => {
          if (typeof input === 'string' || input instanceof Request) {
            try {
            return await fetchWithTimeout(input as RequestInfo, init);
            } catch (error) {
              if ((error as Error)?.name === 'AbortError') {
                const currentRoute = location.pathname
                let path = '/no-internet';
                if (currentRoute.length > 1) {
                  path += currentRoute;
                }
                navigate(path);
              }
              throw error;
            }
          } else {
            return await fetch(input, init);
          }
        },
        headers: async () => {
          const idToken = await getIdToken();
          if (!idToken) return {};
          return {
            authorization: `Bearer ${idToken}`
          }
        }
      })
    ]
  }));

  useEffect(() => {
    if (isAuthenticated) {
      // Intialize the cache
      persistQueryClient({
        // buster: undefined, // Change buster value to invalidate the cache
        queryClient,
        persister: LocalStoragePersistor,
      });
    }
  }, [isAuthenticated, queryClient]);

  useEffect(() => {
    // Clear the cache if the user logs out
    if (previousIsAuthenticated && !isAuthenticated) {
      queryClient.clear();
    }
  }, [isAuthenticated, previousIsAuthenticated, queryClient]);

  return (
    <TrpcClientContext.Provider value={{}}>
      <trpc.Provider client={trpcClient} queryClient={queryClient}>
        <QueryClientProvider client={queryClient}>
          {children}
        </QueryClientProvider>
      </trpc.Provider>
    </TrpcClientContext.Provider>
  );
};
