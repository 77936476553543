import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useAuth from '../hooks/auth.hook';

const LogoutPage: FC = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      logout();
    } else {
      navigate('/');
    }
  }, [isAuthenticated, navigate, logout]);

  return (<></>);
};

export default LogoutPage;
