import { Button } from '@aws-amplify/ui-react';
import { CSSProperties } from 'react';
import { toast, ToastOptions, TypeOptions } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.min.css';

export interface PresentOption {
  title: string;
  action?: () => Promise<void>;
  style?: CSSProperties;
}

interface PresentToastHook {
  presentError: (message?: string) => void;
  presentWarning: (message: string) => void;
  presentSuccess: (message: string) => void;
  presentOptions: (
    message: string,
    options: PresentOption[],
    snackbarOptions?: ToastOptions
  ) => void;
}

const usePresentToast = (): PresentToastHook => {

  const present = (type: TypeOptions, message: string) => {
    toast(message, {
      type,
    });
  };

  const presentError = (message?: string) => {
    present(
      'error',
      message ?? 'Error: Please refresh and try again'
    );
  };

  const presentWarning = (message: string) => {
    present('warning', message);
  };

  const presentSuccess = (message: string) => {
    present('success', message);
  };

  const presentOptions = (
    message: string,
    options: PresentOption[],
    snackbarOptions?: ToastOptions
  ) => {
    toast(
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div>{message}</div>
        <div style={{display: 'flex', flex: 1, justifyContent: 'flex-end'}}>
          {options.map((option) => {
            return (
              <Button
                key={option.title}
                size="small"
                onClick={() => option.action && option.action()}
                style={{ marginRight: '1em', ...option.style }}
              >
                {option.title}
              </Button>
            );
          })}
        </div>
      </div>, {
      type: 'info',
      ...snackbarOptions,
    });
  };

  return {
    presentError,
    presentWarning,
    presentSuccess,
    presentOptions,
  };
};

export default usePresentToast;
