import { FC, ReactNode } from 'react';
import {  Navigate, useLocation } from 'react-router-dom';

import useAuth from '../hooks/auth.hook';

interface ProviderProps {
  children: ReactNode;
}

export const RequireAuth: FC<ProviderProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return <>{children}</>;
}